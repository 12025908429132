import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import BackgroundImage from 'gatsby-background-image';

import theme from '../utils/themeconstants';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Tag from '../components/tag';

const Stage = styled.div`
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
`;

const PageContent = styled.div`
  max-width: 1080px;
  text-align: left;
  padding: 0 0 100px;
  margin: 0 auto;
`;

const BlogFeed = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BlogLink = styled(Link)`
  color: ${theme.colors.primary};
  padding-bottom: 4px;
  transition: color 0.4s;
  &:hover {
    color: ${theme.colors.red};
  }
  &.titleLink {
    padding-bottom: 0;
    text-decoration: none;
  }
`;

const TeaserImageContainer = styled(Link)`
  margin: 0;
  width: 100%;
  overflow: hidden;
`;

const TeaserContent = styled.div``;

const TeaserText = styled.p`
  margin-bottom: ${theme.space.sm}px;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 200px;
`;

const BlogPostsContainer = styled.div`
  flex: 1 1 280px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 16px 40px;
  min-height: 300px;
  background: #fff 50%;
  background-size: cover;
  border-radius: 8px;
  box-shadow: 8px 14px 38px rgba(39, 44, 49, 0.06), 1px 3px 8px rgba(39, 44, 49, 0.03);
  transition: all 0.5s ease;

  &:hover {
    transform: translate3D(0, -1px, 0) scale(1.02);
    box-shadow: 8px 28px 50px rgba(39, 44, 49, 0.07), 1px 6px 12px rgba(39, 44, 49, 0.04);
  }
  &:nth-of-type(6n + 1) {
    flex: 1 1 100%;
    flex-direction: row;
    ${TeaserContent} {
      flex: 1 0 360px;
    }
    ${TeaserImageContainer} {
      flex: 1 1 auto;
    }
    ${StyledBackgroundImage} {
      height: 100%;
    }
    @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px) {
      flex-direction: column;
      ${TeaserContent} {
        flex: 1 1 auto;
      }
      ${StyledBackgroundImage} {
        height: 200px;
      }
    }
  }
`;

const PageLinks = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 2em 0;
`;

const PrevNextLink = styled(Link)`
  color: ${theme.colors.primary};
  text-decoration: none;
  transition: color 0.4s;
  &:hover {
    color: ${theme.colors.red};
  }
`;

const TagList = styled.div`
  margin: 0;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  a {
    margin-right: 8px;
    margin-left: 8px;
  }
  a:first-child {
    margin-left: 0;
  }
  a:last-child {
    margin-right: 0;
  }
`;

class BlogIndex extends React.PureComponent {
  render() {
    const { data, pageContext, location } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const tags = data.tagsGroup.group.map(tag => tag.fieldValue);
    const { currentPage, numPages } = pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? '/blog' : `/blog/${(currentPage - 1).toString()}`;
    const nextPage = `/blog/${(currentPage + 1).toString()}`;
    const currentTag = pageContext.tag.length === 1 ? pageContext.tag[0] : undefined;

    return (
      <Layout dark blog>
        <SEO title="Tomorrow - Blog" keywords={['blog', 'tomorrow', 'climate change']} location={location} />

        <Stage>
          <h3 style={{ marginTop: 70 }}>Tomorrow Blog</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Tag all active={currentTag === undefined} style={{ margin: 8 }} />
            {tags.map(tag => (
              <Tag key={tag} tag={tag} active={tag === currentTag} style={{ margin: 8 }} />
            ))}
          </div>
        </Stage>
        <PageContent>
          <BlogFeed>
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;
              const imageData = node.frontmatter.image.childImageSharp.fluid;
              return (
                <BlogPostsContainer key={node.fields.slug}>
                  <TeaserImageContainer to={`/blog${node.fields.slug}`}>
                    <StyledBackgroundImage Tag="section" fluid={imageData} />
                  </TeaserImageContainer>
                  <TeaserContent
                    style={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Link
                      to={`/blog${node.fields.slug}`}
                      style={{
                        padding: theme.space.lg,
                        paddingBottom: 0,
                        flexGrow: 1,
                        display: 'block',
                        textDecoration: 'none',
                        color: theme.colors.primary,
                      }}
                    >
                      <h3
                        style={{
                          marginBottom: '16px',
                        }}
                      >
                        <BlogLink className="titleLink" to={`/blog${node.fields.slug}`}>
                          {title}
                        </BlogLink>
                      </h3>
                      <small>
                        {node.frontmatter.date}
                        {' · '}
                        {node.fields.readingTime.text}
                      </small>
                      <TeaserText
                        dangerouslySetInnerHTML={{
                          __html: node.excerpt,
                        }}
                      />
                    </Link>
                    <div
                      style={{
                        padding: `${theme.space.md}px ${theme.space.lg}px`,
                      }}
                    >
                      <BlogLink to={`/blog${node.fields.slug}`}>Read More</BlogLink>
                    </div>
                    <small>
                      <TagList style={{ padding: theme.space.md }}>
                        {node.frontmatter.tags.map(tag => (
                          <Tag key={tag} tag={tag} />
                        ))}
                      </TagList>
                    </small>
                  </TeaserContent>
                </BlogPostsContainer>
              );
            })}
          </BlogFeed>
          <PageLinks>
            <div style={{ minWidth: 100 }}>
              {!isFirst && (
                <PrevNextLink isFirst={isFirst} to={prevPage} rel="prev">
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Previous Page
                </PrevNextLink>
              )}
            </div>
            <div>
              {Array.from({ length: numPages }, (_, i) => (
                <PrevNextLink
                  key={`pagination-number${i + 1}`}
                  to={`/blog/${i === 0 ? '' : `${i + 1}/`}`}
                >
                  {`${i + 1}  `}
                </PrevNextLink>
              ))}
            </div>
            <div style={{ minWidth: 100 }}>
              {!isLast && (
                <PrevNextLink isLast={isLast} to={nextPage} rel="next">
                  Next Page <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </PrevNextLink>
              )}
            </div>
          </PageLinks>
        </PageContent>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!, $tag: [String!]) {
    site {
      siteMetadata {
        title
      }
    }
    bgImg: file(relativePath: { eq: "fog.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tagsGroup: allMarkdownRemark(limit: 200, filter: { fields: { collection: { eq: "blog" } } }) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "blog" } }, frontmatter: { tags: { in: $tag } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 160)
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            image {
              childImageSharp {
                fluid(quality: 80, maxWidth: 672) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
