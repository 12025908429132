import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import theme from '../utils/themeconstants';

const LinkTag = styled(Link)`
  color: white;
  text-decoration: none;
  background-color: ${theme.colors.primary};
  padding: 0 ${theme.space.sm}px;
  border-radius: ${theme.space.sm}px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${theme.colors.red};
  }
  &.active {
    background-color: ${theme.colors.red};
  }
`;

const Tag = ({ tag, all, active, style }) => (
  <>
    {all ? (
      <LinkTag className={active ? 'active' : undefined} to="/blog/" style={style}>
        all
      </LinkTag>
    ) : (
      <LinkTag className={active ? 'active' : undefined} to={`/blog/tags/${tag.toLowerCase()}/`} style={style}>
        {tag}
      </LinkTag>
    )}
  </>
);

export default Tag;
